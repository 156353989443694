@import url("https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Edu+AU+VIC+WA+NT+Pre:wght@400..700&family=Oswald:wght@200..700&family=Playwrite+BE+VLG:wght@100..400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Qwitcher+Grypen:wght@400;700&family=Tangerine:wght@400;700&display=swap");
.navbar {
  position: fixed;
  transform: translateX(-50%);
  left: 50%;
  gap: 1rem;
  top: 0;
  z-index: 999;
  background-color: #E2E2B6;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
  margin: 0.5rem;
  border-radius: 20px;
  font-family: "Edu AU VIC WA NT Pre", sans-serif;
}
.navbar .name {
  font-size: 15px;
  font-weight: 500;
  transition: font-style 0.3s ease, font-size 0.3s ease;
  white-space: nowrap; /* Mencegah teks dibungkus ke baris baru */
  overflow: hidden; /* Memastikan teks tidak meluap jika terlalu panjang */
  text-overflow: ellipsis;
}
.navbar .name:hover {
  font-style: italic;
  font-size: 16px;
}
.navbar .burger {
  width: 20px;
}
.navbar .navLinks {
  display: none;
  position: absolute;
  top: 45px;
  background-color: #E2E2B6;
  padding: 1rem;
  border-radius: 20px;
  z-index: 999;
  list-style: none;
  gap: 0.5rem;
  width: 310px;
  align-items: center;
  justify-content: center;
}
.navbar .navLinks li {
  cursor: pointer;
  padding: 0 0.5rem;
}
.navbar .navLinks li a {
  text-decoration: none;
  color: black;
  font-weight: 400;
}
.navbar .navLinks li.is-active {
  padding: 0 0.5rem;
  background-color: #6ec0fd;
  border-radius: 20%;
}
.navbar .navLinks.show {
  display: flex;
  transform: translateX(-50%);
  left: 50%;
}
.navbar .theme-toggle {
  display: "none";
  align-items: center;
  justify-content: center;
}
.navbar .theme-toggle input[type=checkbox] {
  display: none;
}
.navbar .theme-toggle .switch {
  display: none;
  position: relative;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.navbar .theme-toggle .switch .slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}
.navbar .theme-toggle .switch .slider .dayIcon,
.navbar .theme-toggle .switch .slider .nightIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.navbar .theme-toggle .switch .slider .dayIcon {
  opacity: 1;
}
.navbar .theme-toggle input[type=checkbox]:checked + .switch {
  background-color: #1e1e1e;
}
.navbar .theme-toggle input[type=checkbox]:checked + .switch .slider {
  transform: translateX(30px);
}
.navbar .theme-toggle input[type=checkbox]:checked + .switch .slider .dayIcon {
  opacity: 0;
}
.navbar .theme-toggle input[type=checkbox]:checked + .switch .slider .nightIcon {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .navbar .name {
    font-size: 20px;
  }
  .navbar .name:hover {
    font-style: italic;
    font-size: 22px;
  }
  .navbar .navLinks {
    display: flex;
    gap: 70px;
    list-style: none;
    position: relative;
    top: 0;
    right: 0;
    background-color: #E2E2B6;
    padding: 0;
    border-radius: 20px;
    width: auto;
    align-items: center;
    justify-content: center;
  }
  .navbar .navLinks li {
    cursor: pointer;
  }
  .navbar .navLinks li a {
    text-decoration: none;
    color: black;
    font-weight: 400;
    font-size: 20px;
  }
  .navbar .navLinks li.is-active {
    background-color: #6ec0fd;
    border-radius: 20%;
  }
  .navbar .navLinks li:hover {
    color: #333333;
  }
  .navbar .navLinks li::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background-color: #6ec0fd;
    transition: width 0.3s;
  }
  .navbar .navLinks li:hover::after {
    width: 100%;
  }
  .navbar .burger {
    display: none;
  }
  .navbar .theme-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar .theme-toggle input[type=checkbox] {
    display: none;
  }
  .navbar .theme-toggle .switch {
    position: relative;
    width: 80px;
    height: 40px;
    background-color: #ccc;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .navbar .theme-toggle .switch .slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 36px;
    height: 36px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  .navbar .theme-toggle .switch .slider .dayIcon,
  .navbar .theme-toggle .switch .slider .nightIcon {
    width: 24px;
    height: 24px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .navbar .theme-toggle .switch .slider .dayIcon {
    opacity: 1;
  }
  .navbar .theme-toggle input[type=checkbox]:checked + .switch {
    background-color: #1e1e1e;
  }
  .navbar .theme-toggle input[type=checkbox]:checked + .switch .slider {
    transform: translateX(40px);
  }
  .navbar .theme-toggle input[type=checkbox]:checked + .switch .slider .dayIcon {
    opacity: 0;
  }
  .navbar .theme-toggle input[type=checkbox]:checked + .switch .slider .nightIcon {
    opacity: 1;
  }
}/*# sourceMappingURL=navbar.css.map */