@keyframes blinkCursor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.about-section {
  margin-top: 3rem;
}
.about-section .section-text-about {
  text-align: center;
  color: wheat;
  border-bottom: 2px solid;
  font-family: "Edu AU VIC WA NT Pre", sans-serif;
  padding: 1rem;
  margin-top: 1rem;
}
.about-section .section-text-about h1 {
  margin: 0;
}
.about-section .big-container {
  padding: 0 1rem;
}
.about-section .big-container .about-container {
  color: white;
  margin: 2rem auto;
}
.about-section .big-container .about-container .about-text {
  position: relative;
}
.about-section .big-container .about-container .about-text p {
  font-size: 15px;
  text-align: justify;
  line-height: 2;
}
.about-section .big-container .about-container .about-text .blinking-cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: white;
  margin-left: 2px;
  animation: blinkCursor 1s infinite;
}
.about-section .big-container .photo-container {
  text-align: center;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-section .big-container .photo-container .swiper {
  width: 370px;
}
.about-section .big-container .photo-container .swiper .swiper-wrapper {
  align-items: center;
}
.about-section .big-container .photo-container img {
  width: 150px;
  background: white;
  padding: 0.5rem 0.2rem;
  transition: transform 0.3s ease-in-out;
}

@media screen and (min-width: 768px) {
  .about-section {
    margin-top: 5.5rem;
  }
  .about-section .big-container {
    display: flex;
    padding-bottom: 1rem;
    align-items: center;
    gap: 4rem;
  }
  .about-section .big-container .about-container .about-text p {
    font-size: 21px;
    text-align: justify;
  }
  .about-section .big-container .photo-container .swiper {
    width: 300px;
    padding: 1rem;
  }
  .about-section .big-container .photo-container .swiper .swiper-wrapper {
    padding: 0 2rem 2rem 2rem;
  }
  .about-section .big-container .photo-container img {
    width: 200px;
  }
}/*# sourceMappingURL=about.css.map */