.scroll-button {
  position: fixed;
  bottom: 30px;
  right: 5px !important;
  background: #6ec0fd;
  padding: 0.5rem;
  border: none;
  right: 0;
  border-radius: 20px;
  cursor: pointer;
}
.scroll-button img {
  width: 20px;
}/*# sourceMappingURL=scrolltotop.css.map */