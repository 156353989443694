.section-text {
  text-align: center;
  color: wheat;
  border-bottom: 2px solid;
  border-top: 2px solid;
  font-family: "Edu AU VIC WA NT Pre", sans-serif;
  padding: 1rem;

  h1{
    margin: 0;
  }
}

.container-skills {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
  margin: 1.5rem 0;

  .list-skill {
    color: white;
    background: linear-gradient(to bottom, #021526, #333333);
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid #675f5f;
    cursor: pointer;
    transition: background 0.3s ease;
    animation: fadeIn 0.5s ease-out;

    .name-skill {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;

      img {
        width: 60px;
        margin-bottom: 0.5rem;
        transition: transform 0.3s ease;
        &:hover {
          transform: scale(1.1);
        }
      }

      .title {
        font-size: 20px;
        position: relative;
        padding-bottom: 0.5rem;
        font-weight: bold;
        width: fit-content;
        border-bottom: 2px solid #6ec0fd;

        span {
          font-size: 13px;
        }
      }
    }

    .description {
      font-size: 15px;
      text-align: justify;
    }
  }

  // Styling untuk modal
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .modal-content {
    position: absolute;
    background: #021526;
    color: white;
    padding: 2rem;
    border-radius: 1rem;
    width: 80%;
    max-width: 600px;
    box-sizing: border-box;
    cursor: default;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close-btn {
        background: none;
        color: white;
        font-size: 20px;
        cursor: pointer;
        border: none;
      }
    }

    img {
      width: 100px;
      height: auto;
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      h2 {
        font-size: 24px;
        margin: 0.5rem 0;
      }

      p {
        font-size: 16px;
        line-height: 1.6;
        text-align: justify;
        margin: 0.5rem 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;

    .list-skill {
      width: auto;
      flex-basis: 380px;
      box-sizing: border-box;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
