.section-text {
  text-align: center;
  color: wheat;
  border-bottom: 2px solid;
  border-top: 2px solid;
  font-family: "Edu AU VIC WA NT Pre", sans-serif;
  padding: 1rem;
}
.section-text h1 {
  margin: 0;
}

.container-skills {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
  margin: 1.5rem 0;
}
.container-skills .list-skill {
  color: white;
  background: linear-gradient(to bottom, #021526, #333333);
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #675f5f;
  cursor: pointer;
  transition: background 0.3s ease;
  animation: fadeIn 0.5s ease-out;
}
.container-skills .list-skill .name-skill {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}
.container-skills .list-skill .name-skill img {
  width: 60px;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease;
}
.container-skills .list-skill .name-skill img:hover {
  transform: scale(1.1);
}
.container-skills .list-skill .name-skill .title {
  font-size: 20px;
  position: relative;
  padding-bottom: 0.5rem;
  font-weight: bold;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid #6ec0fd;
}
.container-skills .list-skill .name-skill .title span {
  font-size: 13px;
}
.container-skills .list-skill .description {
  font-size: 15px;
  text-align: justify;
}
.container-skills .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.container-skills .modal-content {
  position: absolute;
  background: #021526;
  color: white;
  padding: 2rem;
  border-radius: 1rem;
  width: 80%;
  max-width: 600px;
  box-sizing: border-box;
  cursor: default;
}
.container-skills .modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-skills .modal-content .modal-header .close-btn {
  background: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  border: none;
}
.container-skills .modal-content img {
  width: 100px;
  height: auto;
}
.container-skills .modal-content .modal-body {
  display: flex;
  flex-direction: column;
}
.container-skills .modal-content .modal-body h2 {
  font-size: 24px;
  margin: 0.5rem 0;
}
.container-skills .modal-content .modal-body p {
  font-size: 16px;
  line-height: 1.6;
  text-align: justify;
  margin: 0.5rem 0;
}
@media screen and (min-width: 768px) {
  .container-skills {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
  }
  .container-skills .list-skill {
    width: auto;
    flex-basis: 380px;
    box-sizing: border-box;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=skills.css.map */